html,
body,
#root {
  @apply h-full;
}

body {
  background: url(../img/concrete.png);
  @apply font-sans text-base text-gray-700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold text-gray-800;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

p {
  margin-bottom: 15px;
}

a {
  @apply text-blue-800;
}

img {
  max-width: 100%;
  height: auto;
}
